export const CMS_LIST_REQUEST = 'CMS_LIST_REQUEST'
export const CMS_LIST_SUCCESS = 'CMS_LIST_SUCCESS'
export const CMS_LIST_FAIL = 'CMS_LIST_FAIL'

export const CMS_DETAILS_REQUEST = 'CMS_DETAILS_REQUEST'
export const CMS_DETAILS_SUCCESS = 'CMS_DETAILS_SUCCESS'
export const CMS_DETAILS_FAIL = 'CMS_DETAILS_FAIL'

export const CMS_DELETE_REQUEST = 'CMS_DELETE_REQUEST'
export const CMS_DELETE_SUCCESS = 'CMS_DELETE_SUCCESS'
export const CMS_DELETE_FAIL = 'CMS_DELETE_FAIL'

export const CMS_STATUS_REQUEST = 'CMS_STATUS_REQUEST'
export const CMS_STATUS_SUCCESS = 'CMS_STATUS_SUCCESS'
export const CMS_STATUS_FAIL = 'CMS_STATUS_FAIL'

export const CMS_ADD_REQUEST = 'CMS_ADD_REQUEST'
export const CMS_ADD_SUCCESS = 'CMS_ADD_SUCCESS'
export const CMS_ADD_FAIL = 'CMS_ADD_FAIL'