export const CHALLENGE_LIST_REQUEST = 'CHALLENGE_LIST_REQUEST'
export const CHALLENGE_LIST_SUCCESS = 'CHALLENGE_LIST_SUCCESS'
export const CHALLENGE_LIST_FAIL = 'CHALLENGE_LIST_FAIL'

export const CHALLENGE_DETAILS_REQUEST = 'CHALLENGE_DETAILS_REQUEST'
export const CHALLENGE_DETAILS_SUCCESS = 'CHALLENGE_DETAILS_SUCCESS'
export const CHALLENGE_DETAILS_FAIL = 'CHALLENGE_DETAILS_FAIL'

export const CHALLENGE_DELETE_REQUEST = 'CHALLENGE_DELETE_REQUEST'
export const CHALLENGE_DELETE_SUCCESS = 'CHALLENGE_DELETE_SUCCESS'
export const CHALLENGE_DELETE_FAIL = 'CHALLENGE_DELETE_FAIL'

export const CHALLENGE_STATUS_REQUEST = 'CHALLENGE_STATUS_REQUEST'
export const CHALLENGE_STATUS_SUCCESS = 'CHALLENGE_STATUS_SUCCESS'
export const CHALLENGE_STATUS_FAIL = 'CHALLENGE_STATUS_FAIL'

export const CHALLENGE_ADD_REQUEST = 'CHALLENGE_ADD_REQUEST'
export const CHALLENGE_ADD_SUCCESS = 'CHALLENGE_ADD_SUCCESS'
export const CHALLENGE_ADD_FAIL = 'CHALLENGE_ADD_FAIL'