import {
  ADMIN_LIST_REQUEST,
  ADMIN_LIST_SUCCESS,
  ADMIN_LIST_FAIL,
  ADMIN_DETAILS_REQUEST,
  ADMIN_DETAILS_SUCCESS,
  ADMIN_DETAILS_FAIL,
  ADMIN_DELETE_REQUEST,
  ADMIN_DELETE_SUCCESS,
  ADMIN_DELETE_FAIL,
  ADMIN_STATUS_REQUEST,
  ADMIN_STATUS_SUCCESS,
  ADMIN_STATUS_FAIL,
  ADMIN_FIRE_NOTIFICATION_LOADING,
  ADMIN_FIRE_NOTIFICATION_ERROR,
  ADMIN_FIRE_NOTIFICATION_SUCCESS,
  ADMIN_All_NOTIFICATION_SUCCESS,
  CHANGE_PASS_REQUEST,
  CHANGE_PASS_SUCCESS,
  CHANGE_PASS_FAIL
} from "../constants/adminConstant";

export const adminListReducer = (state = { admins: [] }, action) => {
  switch (action.type) {
    case ADMIN_LIST_REQUEST:
      return { loading: true, admins: [] };
    case ADMIN_LIST_SUCCESS:
      return { loading: false, admins: action.payload };
    case ADMIN_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminDetailsReducer = (
  state = { admin: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case ADMIN_DETAILS_REQUEST:
      return { loading: true, ...state };
    case ADMIN_DETAILS_SUCCESS:
      return { loading: false, admin: action.payload };
    case ADMIN_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminDeleteReducer = (
  state = { admin: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case ADMIN_DELETE_REQUEST:
      return { loading: true, ...state };
    case ADMIN_DELETE_SUCCESS:
      return { loading: false, admin: action.payload };
    case ADMIN_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const adminStatusReducer = (
  state = { admin: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case ADMIN_STATUS_REQUEST:
      return { loading: true, ...state };
    case ADMIN_STATUS_SUCCESS:
      return { loading: false, admin: action.payload };
    case ADMIN_STATUS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const adminAddReducer = (state = { admins: [] }, action) => {
  switch (action.type) {
    case ADMIN_LIST_REQUEST:
      return { loading: true, admins: [] };
    case ADMIN_LIST_SUCCESS:
      return { loading: false, admins: action.payload };
    case ADMIN_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
export const adminChangePassReducer = (state = { admins: [] }, action) => {
  switch (action.type) {
    case CHANGE_PASS_REQUEST:
      return { loading: true, admins: [] };
    case CHANGE_PASS_SUCCESS:
      return { loading: false, admins: action.payload };
    case CHANGE_PASS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const AdminNotificationReducer = (
  state = {
    loading: false,
    error: null,
    NotificationCount: 0,
    NotificationList: [],
    page: 1,
    size: 10,
    totalRecord: 0,
    totalPage: 0,
  },
  action
) => {
  switch (action.type) {
    case ADMIN_FIRE_NOTIFICATION_LOADING:
      return { ...state, loading: true };
    case ADMIN_FIRE_NOTIFICATION_ERROR:
      return { ...state, loading: false, error: action.payload };
    case ADMIN_FIRE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        NotificationCount: action.payload.newNotification,
      };
    case ADMIN_All_NOTIFICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        NotificationList: action.payload?.list,
        page: action.payload?.page,
        size: action.payload?.size,
        totalRecord: action.payload?.totalRecord,
        totalPage: action.payload?.totalPage,
      };

    default:
      return state;
  }
};
