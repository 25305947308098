const initialState = {
  sidebarShow: true,
};

export const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case "set":
      return { ...state, sidebarShow: !state.sidebarShow };
    default:
      return state;
  }
};
