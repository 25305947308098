import React, { useEffect, lazy, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Container from "./PageLoad";
import "./scss/style.scss";
import { fetchToken, onMessageListener } from "./constants/firebase";
import { get } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { LinkButton } from "./StyledElements";
import { useHistory } from "react-router-dom";
const Privacy = lazy(() => import("./views/landing/human-race/Privacy"));
const TermAndCondition = lazy(() =>
  import("./views/landing/human-race/Condition")
);
const Instructions = lazy(() =>
  import("./views/landing/human-race/Instruction")
);
const Landing = lazy(() => import("./views/landing/Landing"));

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

const Login = React.lazy(() => import("./views/pages/login/Login"));

const App = () => {

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "assets/js/theme.js";
    script.async = true;
    script.onload = () => {
      console.log("script loaded");
    };
    document.body.appendChild(script);
    console.log(script);
  }, []);

  const configurePushSub = () => {
    return "helo"
  }
  function askForNPerm() {
    Notification.requestPermission(function(result) {
      console.log("User choice", result);
      if (result !== "granted") {
        console.log("No notification permission granted!");
      } else {
        configurePushSub();// Write your custom function that pushes your message
      }
    });
  }
  useEffect(() => {
    askForNPerm();
  }, [])

  // () {
  // (() => {
  // const script = document.createElement("script");
  // script.src = "assets/js/theme.js";
  // script.async = true;
  // script.onload = () => {
  //   console.log("script loaded");
  // };
  // document.body.appendChild(script);
  // console.log(script);
  // }, []);

  return (
    <>
      <Router>
        <React.Suspense fallback={<Container />}>
          <Switch>
            <Route
              path="/termandcondition"
              name="Term And Condition"
              render={(props) => <TermAndCondition {...props} />}
            />
            <Route
              path="/privacy-policy"
              name="Privacy-Policy"
              render={(props) => <Privacy {...props} />}
            />
            <Route
              path="/instructions"
              name="Instructions"
              render={(props) => <Instructions {...props} />}
            />
            <Route
              exact
              path="/adminlogin"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/"
              name="short Page"
              render={(props) => <Landing {...props} />}
            />
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </Router>
      <ToastContainer />
    </>
  );
};

export default App;
