export const REVIEWER_LIST_REQUEST = "REVIEWER_LIST_REQUEST";
export const REVIEWER_LIST_SUCCESS = "REVIEWER_LIST_SUCCESS";
export const REVIEWER_LIST_FAIL = "REVIEWER_LIST_FAIL";

export const REVIEWER_DETAILS_REQUEST = "REVIEWER_DETAILS_REQUEST";
export const REVIEWER_DETAILS_SUCCESS = "REVIEWER_DETAILS_SUCCESS";
export const REVIEWER_DETAILS_FAIL = "REVIEWER_DETAILS_FAIL";

export const REVIEWER_DELETE_REQUEST = "REVIEWER_DELETE_REQUEST";
export const REVIEWER_DELETE_SUCCESS = "REVIEWER_DELETE_SUCCESS";
export const REVIEWER_DELETE_FAIL = "REVIEWER_DELETE_FAIL";

export const REVIEWER_STATUS_REQUEST = "REVIEWER_STATUS_REQUEST";
export const REVIEWER_STATUS_SUCCESS = "REVIEWER_STATUS_SUCCESS";
export const REVIEWER_STATUS_FAIL = "REVIEWER_STATUS_FAIL";

export const REVIEWER_ADD_REQUEST = "REVIEWER_ADD_REQUEST";
export const REVIEWER_ADD_SUCCESS = "REVIEWER_ADD_SUCCESS";
export const REVIEWER_ADD_FAIL = "REVIEWER_ADD_FAIL";
