export const CATEGORY_LIST_REQUEST = 'CATEGORY_LIST_REQUEST'
export const CATEGORY_LIST_SUCCESS = 'CATEGORY_LIST_SUCCESS'
export const CATEGORY_LIST_FAIL = 'CATEGORY_LIST_FAIL'

export const CATEGORY_DETAILS_REQUEST = 'CATEGORY_DETAILS_REQUEST'
export const CATEGORY_DETAILS_SUCCESS = 'CATEGORY_DETAILS_SUCCESS'
export const CATEGORY_DETAILS_FAIL = 'CATEGORY_DETAILS_FAIL'

export const CATEGORY_DELETE_REQUEST = 'CATEGORY_DELETE_REQUEST'
export const CATEGORY_DELETE_SUCCESS = 'CATEGORY_DELETE_SUCCESS'
export const CATEGORY_DELETE_FAIL = 'CATEGORY_DELETE_FAIL'

export const CATEGORY_STATUS_REQUEST = 'CATEGORY_STATUS_REQUEST'
export const CATEGORY_STATUS_SUCCESS = 'CATEGORY_STATUS_SUCCESS'
export const CATEGORY_STATUS_FAIL = 'CATEGORY_STATUS_FAIL'

export const CATEGORY_ADD_REQUEST = 'CATEGORY_ADD_REQUEST'
export const CATEGORY_ADD_SUCCESS = 'CATEGORY_ADD_SUCCESS'
export const CATEGORY_ADD_FAIL = 'CATEGORY_ADD_FAIL'