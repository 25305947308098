export const ADMIN_LIST_REQUEST = "ADMIN_LIST_REQUEST";
export const ADMIN_LIST_SUCCESS = "ADMIN_LIST_SUCCESS";
export const ADMIN_LIST_FAIL = "ADMIN_LIST_FAIL";

export const ADMIN_DETAILS_REQUEST = "ADMIN_DETAILS_REQUEST";
export const ADMIN_DETAILS_SUCCESS = "ADMIN_DETAILS_SUCCESS";
export const ADMIN_DETAILS_FAIL = "ADMIN_DETAILS_FAIL";

export const ADMIN_DELETE_REQUEST = "ADMIN_DELETE_REQUEST";
export const ADMIN_DELETE_SUCCESS = "ADMIN_DELETE_SUCCESS";
export const ADMIN_DELETE_FAIL = "ADMIN_DELETE_FAIL";

export const ADMIN_STATUS_REQUEST = "ADMIN_STATUS_REQUEST";
export const ADMIN_STATUS_SUCCESS = "ADMIN_STATUS_SUCCESS";
export const ADMIN_STATUS_FAIL = "ADMIN_STATUS_FAIL";

export const ADMIN_ADD_REQUEST = "ADMIN_ADD_REQUEST";
export const ADMIN_ADD_SUCCESS = "ADMIN_ADD_SUCCESS";
export const ADMIN_ADD_FAIL = "ADMIN_ADD_FAIL";

export const ADMIN_FIRE_NOTIFICATION_SUCCESS =
  "ADMIN_FIRE_NOTIFICATION_SUCCESS";
export const ADMIN_All_NOTIFICATION_SUCCESS = "ADMIN_All_NOTIFICATION_SUCCESS";
export const ADMIN_FIRE_NOTIFICATION_ERROR = "ADMIN_FIRE_NOTIFICATION_ERROR";
export const ADMIN_FIRE_NOTIFICATION_LOADING =
  "ADMIN_FIRE_NOTIFICATION_LOADING";

  
export const CHANGE_PASS_REQUEST = 'CHANGE_PASS_REQUEST';
export const CHANGE_PASS_SUCCESS = 'CHANGE_PASS_SUCCESS';
export const CHANGE_PASS_FAIL = 'CHANGE_PASS_FAIL';