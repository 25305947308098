import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { getDatabase, ref, set, push, child, get, onValue } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyA-aVQLlcqHg9E8dq_Qw7EdbHWkMb9_H68",
    authDomain: "nother-ab595.firebaseapp.com",
    projectId: "nother",
    storageBucket: "nother.appspot.com",
    messagingSenderId: "749834165716",
    appId: "1:749834165716:web:f2ae501aadb2b2e2b58a41",
    measurementId: "G-MMBRDT0R4J"
  };
  

// to access database
const app = initializeApp(firebaseConfig);
const firestore = getFirestore();
const db = getDatabase();

// const db = firebaseApp.firestore();

// const auth = firebase.auth()

const firebaseMessaging = getMessaging(app);

const fetchToken = async (setTokenFound) => {
  // console.log(setTokenFound);
  return getToken(firebaseMessaging, {
    vapidKey: "BCdgk60hNpsPKTXLylHcoh4M1SbxkdVYICdLQHuuK2_5x01stZUW-IxwQXvr1klmhX8-U8DtC79zrio25qP4D98",
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        setTokenFound(currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        // console.log('No registration token available. Request permission to generate one.');
        setTokenFound(currentToken);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
// const requestForToken = () => {
//   return getToken(firebaseMessaging, { vapidKey: `BH9x5FVAN-Nev8wUZW12_GL14jGnmYBtsj-xXPPhY5X-gthO97tPvxv6_V227cBGpY6Qhj9keLij9keS7t3RiMo` })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log('current token for client: ', currentToken);
//         // Perform any other neccessary action with the token
//       } else {
//         // Show permission request UI
//         console.log('No registration token available. Request permission to generate one.');
//       }
//     })
//     .catch((err) => {
//       console.log('An error occurred while retrieving token. ', err);
//     });
// };

const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(firebaseMessaging, (payload) => {
      // console.log(payload);
      resolve(payload);
    });
  });
const FCM_KEY = "BCdgk60hNpsPKTXLylHcoh4M1SbxkdVYICdLQHuuK2_5x01stZUW-IxwQXvr1klmhX8-U8DtC79zrio25qP4D98";

export { firestore, db, app, fetchToken, onMessageListener, FCM_KEY };
